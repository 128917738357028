<template>
  <v-dialog v-model="dlgShow" scrollable max-width="400px">
    <v-card class="pa-3">
      <v-card-text>
        <div class="layout column align-center">
          <!-- <img src="../../../public/img/bac_logo.svg" alt="Bengaluru Allergy Centr">
              <div class="display-3 green--text text--darken-4 font-weight-medium">Bengaluru</div>-->
          <h1 class="flex my-4 green--text text--darken-4">Sign In</h1>
        </div>
        <v-form>
          <v-text-field
              outlined
              v-model="model.username"
              v-validate="{ required: true }"
              :error="!!errors.has('login')"
              :error-messages="errors.first('login')"
              name="login"
              label="Email"
          />
          <v-text-field
              outlined
              id="password"
              v-model="model.password"
              name="password"
              label="Password"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              @click:append="showPassword = !showPassword"
          />
          <div v-show="isSubmissionError">
            <span class="red--text">{{ submissionErrorMessage }}</span>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" :loading="isLoading" @click="login"
        >Login
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WebH from "../../api/web";

export default {
  props: {
    showDlg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dlgShow: {
      get() {
        return this.showDlg;
      },
      set(value) {
        this.$emit("close", value);
      }
    }
  },
  data: () => ({
    showPassword: false,
    isLoading: false,
    // model: {
    //   username: "admin@bac.com",
    //   password: "Bac+123"
    // },
    model: {
      username: "",
      password: ""
    },
    isSubmissionError: false,
    submissionErrorMessage: ""
  }),
  methods: {
    login() {
      this.isSubmissionError = false;
      this.submissionErrorMessage = "";
      this.$validator.validateAll().then(result => {
        if (result) {
          const data = {
            UserName: this.model.username,
            Password: this.model.password
          };
          this.api_signIn(data);
        }
      });
    },
    async api_signIn(data) {
      this.isLoading = true;
      //const wh = new WebH();
      const res = await WebH.Post("/Auth", data);
      this.isLoading = false;
      if (res.Success) {
        await this.$store.dispatch("UserStore/signIn", res.Data);
        this.$store.dispatch("SettingsStore/loadSettings");
        if (this.$store.getters["UserStore/permissions"].includes("dr.v"))
          this.$store.dispatch("DrugsStore/loadDrugs");
        this.$emit("close");
        this.$router.replace("/");
      } else {
        this.submissionErrorMessage = res.Data;
        this.isSubmissionError = true;
      }
    }
  }
};
</script>
