<template>
  <!-- <v-app class='green lighten-5'> -->
  <v-app>
    <v-main>
      <v-container grid-list-xl fluid>
        <v-layout row wrap>
          <v-app-bar app>
            <v-app-bar-title>
              <img
                src='../../../public/img/bac_logo.svg'
                width='90px'
                height='40px'
                alt='Bengaluru Allergy Centr'
              />
            </v-app-bar-title>
            <v-spacer></v-spacer>`
            <div
              class='display-2 green--text text--darken-4 font-weight-medium hidden-sm-and-down'
              style='text-align: center;align-content: center;'
            >
              Bengaluru Allergy Center
            </div>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-menu offset-y>
                <template v-slot:activator='{ on }'>
                  <v-btn text
                         v-on="on">
                    <v-icon class='iconText' color='green darken-4'
                    >fa fa-file-alt</v-icon
                    >
                    <div class='green--text text--darken-4'>
                      Forms
                    </div>
                  </v-btn>
                </template>


                <v-list>
                  <v-list-item @click='showApptForm'>
                    <v-list-item>Appointment</v-list-item>
                  </v-list-item>
                  <v-list-item @click='showAllergyQuestForm'>
                    <v-list-item>Patient Questionnaire</v-list-item>
                  </v-list-item>
                  <v-list-item @click='showMoreQuestionsForm'>
                    <v-list-item>Allergy Survey Sheet</v-list-item>
                  </v-list-item>
                  <!--<v-list-item @click='showHabitForm'>-->
                  <!--<v-list-item>Habits</v-list-item>-->
                  <!--</v-list-item>-->
                </v-list>
              </v-menu>
              <v-btn text @click='dlgShow = true'>
                <v-icon class='iconText' color='green darken-4'
                  >fa fa-clinic-medical</v-icon
                >
                <div class='green--text text--darken-4'>
                  Sign In
                </div></v-btn
              >
            </v-toolbar-items>
          </v-app-bar>
          <v-flex sm12 lg12 xs12>
            <router-view></router-view>
            <!--            <ApptCreate></ApptCreate>-->
          </v-flex>
          <v-flex sm12 xs12 lg12>
            <LoginComp :showDlg='dlgShow' @close='closeDlg'></LoginComp>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
//import ApptCreate from './CreateAppointment';
import LoginComp from './Login';
export default {
  components: {
    //ApptCreate,
    LoginComp
  },
  data() {
    return {
      dlgShow: false
    };
  },
  methods: {
    closeDlg() {
      this.dlgShow = false;
    },
    showApptForm() {
      this.$router.push('/book-appointment');
    },
    showAllergyQuestForm() {
      this.$router.push('/allergy-questions');
    },
    showMoreQuestionsForm() {
      this.$router.push('/more-questions');
    },
    showHabitForm() {
      this.$router.push('/habits-form');
    }
  }
};
</script>
